import React from 'react';

interface StockRangeChartProps {
  bearPrice: number;
  basePrice: number;
  bullPrice: number;
  currentPrice: number;
}

const StockRangeChart: React.FC<StockRangeChartProps> = ({ bearPrice, basePrice, bullPrice, currentPrice }) => {
  const minPrice = currentPrice < bearPrice ? currentPrice : bearPrice;
  const maxPrice = currentPrice > bullPrice ? currentPrice : bullPrice;

  const calculatePosition = (price: number) => {
    return ((price - minPrice) / (maxPrice - minPrice)) * 100;
  };

  const bearPosition = calculatePosition(bearPrice);
  const basePosition = calculatePosition(basePrice);
  const bullPosition = calculatePosition(bullPrice);
  const currentPosition = calculatePosition(currentPrice);

  return (
    <div className="relative pt-8 pb-8"> {/* Increased top padding */}
      {/* Current price label */}
      <div 
        className="absolute text-sm font-bold text-blue-600 transform -translate-x-1/2"
        style={{ left: `${currentPosition}%`, top: '20px' }}
      >
        {currentPrice.toFixed(2)}
      </div>
      {/* Main bar */}
      <div className="h-1 bg-gray-200 rounded-full overflow-hidden mt-4"> {/* Added margin top */}
        {/* Bear-Bull range */}
        <div 
          className="absolute h-1 bg-gray-400"
          style={{ left: `${bearPosition}%`, right: `${100 - bullPosition}%` }}
        ></div>
        {/* Base price marker */}
       
        {/* Current price marker */}
        <div 
          className="absolute w-0.5 h-3 bg-blue-600 transform -translate-x-1/2"
          style={{ left: `${currentPosition}%`, top: '45px' }}
        ></div>
      </div>
      {/* Price labels */}
      <div className="flex justify-between mt-4 text-xs font-semibold text-gray-600">
        <span>Bear: {bearPrice.toFixed(2)}</span>
        <span>Base: {basePrice.toFixed(2)}</span>
        <span>Bull: {bullPrice.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default StockRangeChart;
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { StockData } from '../utils/api';
import StockInfo from './StockInfo';

interface StockDetailProps {
  stock: StockData;
  addToWatchlist: (stock: StockData) => void;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  isInWatchlist: (stockName: string) => boolean;
}

const StockDetail: React.FC<StockDetailProps> = ({ stock, addToWatchlist, addToPortfolio, isInWatchlist }) => {
  if (!stock) {
    return <div>Stock not found</div>;
  }

  const slug = stock.name.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="max-w-3xl mx-auto mt-8">
      <Helmet>
        <title>{`${stock.name} Stock Analysis | Stock Analysis App`}</title>
        <meta name="description" content={`Detailed analysis of ${stock.name} stock, including quality, value, and momentum rankings.`} />
        <meta property="og:title" content={`${stock.name} Stock Analysis | Stock Analysis App`} />
        <meta property="og:description" content={`Detailed analysis of ${stock.name} stock, including quality, value, and momentum rankings.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://yourdomain.com/stock/${slug}`} />
      </Helmet>
      <Link to="/" className="text-blue-500 hover:underline mb-4 block">&larr; Back to Stock List</Link>
      <div className="bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="p-6">
          <StockInfo stock={stock} showFullDetails={true} />
        </div>
      </div>
      {!isInWatchlist(stock.name) ? (
        <button
          onClick={() => addToWatchlist(stock)}
          className="w-full mt-6 bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200 font-semibold"
        >
          Add to Watchlist
        </button>
      ) : (
        <div className="w-full mt-6 bg-green-100 text-green-800 py-3 px-4 rounded-lg text-center font-semibold">
          Already in Watchlist
        </div>
      )}
    </div>
  );
};

export default StockDetail;
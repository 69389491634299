import React from 'react';
import StockCard from './StockCard';
import { StockData } from '../utils/api';

interface StockListProps {
  stocks: StockData[];
  addToWatchlist: (stockId: string) => Promise<void>;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  watchlistIds: string[];
}

const StockList: React.FC<StockListProps> = ({ stocks, addToWatchlist, addToPortfolio, watchlistIds }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {stocks.map(stock => (
        <StockCard 
          key={stock.id} 
          stock={stock} 
          addToWatchlist={() => addToWatchlist(stock.id)} 
          onAddToPortfolio={addToPortfolio}
          isInWatchlist={watchlistIds.includes(stock.id)}
        />
      ))}
    </div>
  );
};

export default StockList;
import React from 'react';
import { StockData } from '../utils/api';

interface WatchlistProps {
  watchlist: StockData[];
  removeFromWatchlist: (stockId: string) => void;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
}

const Watchlist: React.FC<WatchlistProps> = ({ watchlist, removeFromWatchlist, addToPortfolio }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Din Bevakningslista</h2>
      {watchlist.length === 0 ? (
        <p>Din bevakningslista är tom. Lägg till aktier från huvudlistan eller aktiedetaljsidan.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticker</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Namn</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sektor</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Q</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">V</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">M</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tot</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Åtgärder</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {watchlist.map((stock) => (
                <tr key={stock.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.ticker}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.sector}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.qualityRank.toFixed(1)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.valueRank.toFixed(1)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.momentumRank.toFixed(1)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.totalRank.toFixed(1)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => removeFromWatchlist(stock.id)}
                      className="text-red-600 hover:text-red-900 mr-2"
                    >
                      Ta bort
                    </button>
                    <button
                      onClick={() => addToPortfolio(stock.id, 1, stock.currentPrice)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      Lägg till i portfölj
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Watchlist;
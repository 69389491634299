import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Watchlist from '../components/Watchlist';
import { StockData, fetchStockDataById } from '../utils/api';

interface WatchlistPageProps {
  watchlistIds: string[];
  removeFromWatchlist: (stockId: string) => void;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
}

const WatchlistPage: React.FC<WatchlistPageProps> = ({ watchlistIds, removeFromWatchlist, addToPortfolio }) => {
  const [watchlistStocks, setWatchlistStocks] = useState<StockData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWatchlistStocks = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const stocks = await Promise.all(watchlistIds.map(id => fetchStockDataById(id)));
        setWatchlistStocks(stocks);
      } catch (err) {
        setError('Failed to load watchlist stocks. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWatchlistStocks();
  }, [watchlistIds]);

  if (isLoading) {
    return <div className="text-center mt-8">Loading watchlist...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div>
      <Header />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800">Your Watchlist</h1>
        <Watchlist watchlist={watchlistStocks} removeFromWatchlist={removeFromWatchlist} addToPortfolio={addToPortfolio} />
      </main>
    </div>
  );
};

export default WatchlistPage;
import React from 'react';
import { StockData } from '../utils/api';
import { getRankColor } from '../utils/helpers';
import StockRangeChart from './StockRangeChart';

interface StockInfoProps {
  stock: StockData;
  showFullDetails?: boolean;
}

const StockInfo: React.FC<StockInfoProps> = ({ stock, showFullDetails = false }) => {
  return (
    <div className={`bg-white ${showFullDetails ? 'p-6' : 'p-4'} rounded-xl`}>
      <div className="flex justify-between items-start mb-4">
        <h2 className={`${showFullDetails ? 'text-2xl' : 'text-xl'} font-semibold`}>{stock.name}</h2>
        <div className={`text-2xl font-bold ${getRankColor(stock.totalRank)}`}>{stock.totalRank}</div>
      </div>
      <p className="text-sm text-gray-500 mb-4">{stock.country} - {stock.sector}</p>
      <div className="grid grid-cols-3 gap-4 mb-6 text-center">
        <div>
          <span className="text-sm text-gray-500 block mb-1">Quality</span>
          <span className={`text-lg font-semibold ${getRankColor(stock.qualityRank)}`}>{stock.qualityRank}</span>
        </div>
        <div>
          <span className="text-sm text-gray-500 block mb-1">Value</span>
          <span className={`text-lg font-semibold ${getRankColor(stock.valueRank)}`}>{stock.valueRank}</span>
        </div>
        <div>
          <span className="text-sm text-gray-500 block mb-1">Momentum</span>
          <span className={`text-lg font-semibold ${getRankColor(stock.momentumRank)}`}>{stock.momentumRank}</span>
        </div>
      </div>
      <div className="mb-4">
        <StockRangeChart 
          bearPrice={stock.bearPrice}
          basePrice={stock.basePrice}
          bullPrice={stock.bullPrice}
          currentPrice={stock.currentPrice}
        />
      </div>
    </div>
  );
};

export default StockInfo;
import React from 'react';
import { useParams } from 'react-router-dom';
import StockDetail from '../components/StockDetail';
import { StockData, fetchStockData } from '../utils/api';
import Header from '../components/Header';

interface StockDetailPageProps {
  addToWatchlist: (stockId: string) => Promise<void>;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  isInWatchlist: (id: string) => boolean;
}

const StockDetailPage: React.FC<StockDetailPageProps> = ({ addToWatchlist, addToPortfolio, isInWatchlist }) => {
  const { slug } = useParams<{ slug: string }>();
  const [stock, setStock] = React.useState<StockData | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const loadStock = async () => {
      try {
        const stocks = await fetchStockData();
        const foundStock = stocks.find(s => s.name.toLowerCase().replace(/\s+/g, '-') === slug);
        if (foundStock) {
          setStock(foundStock);
        } else {
          setError('Stock not found');
        }
      } catch (err) {
        setError('Failed to load stock data');
      } finally {
        setIsLoading(false);
      }
    };

    loadStock();
  }, [slug]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!stock) {
    return <div>Stock not found</div>;
  }

  return (
    <div>
      <Header />
      <StockDetail
        stock={stock}
        addToWatchlist={() => addToWatchlist(stock.id)}
        addToPortfolio={addToPortfolio}
        isInWatchlist={() => isInWatchlist(stock.id)}
      />
    </div>
  );
};

export default StockDetailPage;
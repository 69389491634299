import React, { useState } from 'react';
import { StockData } from '../utils/api';
import AddToPortfolioModal from './AddToPortfolioModal';
import { FiPlusCircle, FiCheckCircle, FiBriefcase } from 'react-icons/fi';

interface StockCardProps {
  stock: StockData;
  addToWatchlist: () => void;
  onAddToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  isInWatchlist: boolean;
}

const StockCard: React.FC<StockCardProps> = ({ stock, addToWatchlist, isInWatchlist, onAddToPortfolio }) => {
  const [showModal, setShowModal] = useState(false);

  const getTrendStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'bearish':
        return 'text-red-600';
      case 'bullish':
        return 'text-green-600';
      case 'neutral':
        return 'text-yellow-600';
      default:
        return 'text-gray-600';
    }
  };

  const getSliderPosition = () => {
    const range = stock.bullPrice - stock.bearPrice;
    const position = ((stock.currentPrice - stock.bearPrice) / range) * 100;
    return Math.min(Math.max(position, 0), 100);
  };

  const getCurrencySymbol = (country: string) => {
    switch (country.toLowerCase()) {
      case 'sweden':
      case 'denmark':
      case 'norway':
        return 'kr';
      case 'finland':
        return '€';
      case 'usa':
        return '$';
      default:
        return '$'; // Default to USD if country is not recognized
    }
  };

  const formatPrice = (price: number, country: string) => {
    const symbol = getCurrencySymbol(country);
    const formattedPrice = country.toLowerCase() === 'usa' ? price.toFixed(2) : Math.round(price).toString();
    return `${formattedPrice} ${symbol}`;
  };

  const getTotalScoreColor = (score: number) => {
    if (score >= 3) return 'bg-green-500 text-white';
    if (score >= -1) return 'bg-orange-500 text-white';
    return 'bg-red-500 text-white';
  };

  const handleAddToPortfolio = async (stockId: string, quantity: number, buyPrice: number) => {
    await onAddToPortfolio(stockId, quantity, buyPrice);
    setShowModal(false);
  };

  return (
    <div className="bg-white shadow-lg rounded-xl p-6 m-4 transition-all duration-300 hover:shadow-xl relative">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h2 className="text-2xl font-bold text-gray-800">{stock.name}</h2>
          <p className="text-sm text-gray-600">{stock.sector}</p>
        </div>
        <span className={`text-lg font-semibold rounded-full px-3 py-1 ${getTotalScoreColor(stock.totalRank)}`}>
          {stock.totalRank.toFixed(2)}
        </span>
      </div>
      <p className={`text-sm font-semibold ${getTrendStatusColor(stock.trendStatus)} mb-4`}>
        Trend: {stock.trendStatus}
      </p>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="text-center">
          <p className="text-xs text-gray-500">Quality</p>
          <p className="font-semibold">{stock.qualityRank.toFixed(2)}</p>
        </div>
        <div className="text-center">
          <p className="text-xs text-gray-500">Value</p>
          <p className="font-semibold">{stock.valueRank.toFixed(2)}</p>
        </div>
        <div className="text-center">
          <p className="text-xs text-gray-500">Momentum</p>
          <p className="font-semibold">{stock.momentumRank.toFixed(2)}</p>
        </div>
      </div>
      <div className="mb-6">
        <div className="flex justify-between text-sm mb-2">
          <span>Bear: {formatPrice(stock.bearPrice, stock.country)}</span>
          <span>Base: {formatPrice(stock.basePrice, stock.country)}</span>
          <span>Bull: {formatPrice(stock.bullPrice, stock.country)}</span>
        </div>
        <div className="relative h-2 bg-gray-200 rounded-full">
          <div 
            className="absolute h-full bg-blue-500 rounded-full"
            style={{ width: `${getSliderPosition()}%` }}
          ></div>
          <div 
            className="absolute w-4 h-4 bg-blue-600 rounded-full -mt-1 shadow"
            style={{ left: `calc(${getSliderPosition()}% - 8px)` }}
          ></div>
        </div>
        <p className="text-center mt-2 font-semibold">
          Current: {formatPrice(stock.currentPrice, stock.country)}
        </p>
      </div>
      <div className="flex justify-end mt-4">
        <button
          onClick={addToWatchlist}
          className={`p-2 rounded-full transition-colors duration-300 ${
            isInWatchlist ? 'text-gray-400 cursor-not-allowed' : 'text-gray-600 hover:text-gray-800'
          }`}
          disabled={isInWatchlist}
          title={isInWatchlist ? 'In Watchlist' : 'Add to Watchlist'}
        >
          {isInWatchlist ? <FiCheckCircle size={24} /> : <FiPlusCircle size={24} />}
        </button>
        <button
          onClick={() => setShowModal(true)}
          className="p-2 rounded-full text-gray-600 hover:text-gray-800 transition-colors duration-300 ml-2"
          title="Add to Portfolio"
        >
          <FiBriefcase size={24} />
        </button>
      </div>
      {showModal && (
        <AddToPortfolioModal
          stock={stock}
          onAdd={handleAddToPortfolio}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default StockCard;
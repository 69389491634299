import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const Home: React.FC = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <main>
        {/* Hero section with animated background */}
        <div className="relative overflow-hidden bg-gray-900 h-[50vh] flex items-center">
          {/* Animated background */}
          <div className="absolute inset-0 z-0">
            <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" style={{stopColor: 'rgba(79, 70, 229, 0.2)', stopOpacity:1}} />
                  <stop offset="100%" style={{stopColor: 'rgba(16, 185, 129, 0.2)', stopOpacity:1}} />
                </linearGradient>
              </defs>
              <rect width="100" height="100" fill="url(#grad1)">
                <animate attributeName="opacity" values="0.5;0.3;0.5" dur="4s" repeatCount="indefinite" />
              </rect>
              <path d="M0 50 Q 25 25, 50 50 T 100 50" stroke="rgba(255,255,255,0.3)" strokeWidth="0.5" fill="none">
                <animate attributeName="d" 
                  values="M0 50 Q 25 25, 50 50 T 100 50;
                          M0 50 Q 25 75, 50 50 T 100 50;
                          M0 50 Q 25 25, 50 50 T 100 50"
                  dur="10s" repeatCount="indefinite" />
              </path>
              <path d="M0 50 Q 25 75, 50 50 T 100 50" stroke="rgba(255,255,255,0.3)" strokeWidth="0.5" fill="none">
                <animate attributeName="d" 
                  values="M0 50 Q 25 75, 50 50 T 100 50;
                          M0 50 Q 25 25, 50 50 T 100 50;
                          M0 50 Q 25 75, 50 50 T 100 50"
                  dur="10s" repeatCount="indefinite" />
              </path>
              <g>
                {[...Array(20)].map((_, i) => (
                  <circle key={i} cx={Math.random() * 100} cy={Math.random() * 100} r="0.3" fill="#fff">
                    <animate attributeName="opacity" values="0;1;0" dur={`${Math.random() * 3 + 2}s`} repeatCount="indefinite" />
                  </circle>
                ))}
              </g>
            </svg>
          </div>
          {/* Content */}
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <p className="text-base font-semibold text-indigo-400 tracking-wide uppercase">AI-driven och betrodd av investerare över hela världen</p>
            <h1 className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">AI-baserad aktieanalys i realtid</h1>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-300">Fatta bättre investeringsbeslut med hjälp av AI som anpassar sig till din strategi.</p>
            <Link to="/skapa-konto" className="mt-8 inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition duration-150 ease-in-out transform hover:scale-105">
              Börja din kostnadsfria testperiod på några minuter
            </Link>
          </div>
        </div>

        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Features section */}
          <div className="py-12 bg-white">
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <h2 className="sr-only">Våra fördelar</h2>
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-16 w-16 rounded-md bg-indigo-500 text-white">
                      <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-6">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Inget behov av förkunskaper
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Passar både nybörjare och erfarna investerare
                    </dd>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-16 w-16 rounded-md bg-indigo-500 text-white">
                      <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-6">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Snabb marknadsdata i realtid
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Alltid uppdaterad med minimal fördröjning
                    </dd>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-16 w-16 rounded-md bg-indigo-500 text-white">
                      <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-6">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Fullt anpassningsbar
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Skapa egna dashboards som passar din investeringsstrategi
                    </dd>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-16 w-16 rounded-md bg-indigo-500 text-white">
                      <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-6">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Säkerhet i världsklass
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Ditt data skyddas med den senaste tekniken för datasäkerhet
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>

          {/* Testimonial section */}
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto text-center">
              <p className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Betrodd av investerare</p>
              <h2 className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
                Används av över 10 000 investerare
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                "StockSmart har revolutionerat mitt sätt att analysera aktier. Det är som att ha en personlig finansiell rådgivare tillgänglig dygnet runt."
              </p>
              <p className="mt-3 font-medium text-gray-900">- Anna Andersson, Privat Investerare</p>
            </div>
          </div>

          {/* CTA section */}
          <div className="bg-indigo-700">
            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Redo att börja?</span>
                <span className="block">Starta din kostnadsfria testperiod idag.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Lås upp kraften i intelligent aktieanalys och ta din investering till nästa nivå.
              </p>
              <div className="mt-8 flex justify-center space-x-4">
                <Link to="/skapa-konto" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 transition duration-150 ease-in-out transform hover:scale-105">
                  Börja gratis
                </Link>
                <Link to="/sa-funkar-det" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 transition duration-150 ease-in-out transform hover:scale-105">
                  Läs mer om våra funktioner
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
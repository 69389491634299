import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { auth, db } from './firebase/config';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import StockListPage from './pages/StockListPage';
import WatchlistPage from './pages/WatchlistPage';
import StockDetailPage from './pages/StockDetailPage';
import SignUp from './pages/SignUp';
import MinaSidor from './pages/MinaSidor';
import { StockData, fetchStockDataById } from './utils/api';
import Login from './pages/Login';

// Definiera PortfolioItem interface
interface PortfolioItem extends StockData {
  quantity: number;
  buyPrice: number;
}

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [watchlistIds, setWatchlistIds] = useState<string[]>([]);
  const [portfolio, setPortfolio] = useState<PortfolioItem[]>([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchWatchlistIds(currentUser.uid);
        fetchPortfolio(currentUser.uid);
      } else {
        setWatchlistIds([]);
        setPortfolio([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchWatchlistIds = async (userId: string) => {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      setWatchlistIds(userDoc.data().watchlist || []);
    }
  };

  const fetchPortfolio = async (userId: string) => {
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const portfolioData = userDoc.data().portfolio || [];
      const updatedPortfolio = await Promise.all(
        portfolioData.map(async (item: PortfolioItem) => {
          try {
            const stockData = await fetchStockDataById(item.id);
            return {
              ...stockData,
              quantity: item.quantity,
              buyPrice: item.buyPrice
            };
          } catch (error) {
            console.error(`Error fetching data for stock ${item.id}:`, error);
            return item;
          }
        })
      );
      setPortfolio(updatedPortfolio);
    }
  };

  const addToWatchlist = async (stockId: string) => {
    if (user) {
      if (!stockId) {
        console.error('Attempted to add undefined stockId to watchlist');
        return;
      }
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        watchlist: arrayUnion(stockId)
      }, { merge: true });
      setWatchlistIds(prev => [...prev, stockId]);
    } else {
      console.log("User must be logged in to add to watchlist");
    }
  };

  const removeFromWatchlist = async (stockId: string) => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        watchlist: arrayRemove(stockId)
      }, { merge: true });
      setWatchlistIds(prev => prev.filter(id => id !== stockId));
    } else {
      console.log("User must be logged in to remove from watchlist");
    }
  };

  const addToPortfolio = async (stockId: string, quantity: number, buyPrice: number) => {
    if (user) {
      try {
        const stockData = await fetchStockDataById(stockId);
        const newItem: PortfolioItem = {
          ...stockData,
          quantity,
          buyPrice
        };
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        let updatedPortfolio: PortfolioItem[];
        if (userDoc.exists()) {
          const currentPortfolio = userDoc.data().portfolio || [];
          const existingItemIndex = currentPortfolio.findIndex((item: PortfolioItem) => item.id === stockId);
          if (existingItemIndex !== -1) {
            // Update existing item
            updatedPortfolio = [...currentPortfolio];
            updatedPortfolio[existingItemIndex].quantity += quantity;
            updatedPortfolio[existingItemIndex].buyPrice = 
              (updatedPortfolio[existingItemIndex].buyPrice * updatedPortfolio[existingItemIndex].quantity + buyPrice * quantity) / 
              (updatedPortfolio[existingItemIndex].quantity + quantity);
          } else {
            // Add new item
            updatedPortfolio = [...currentPortfolio, newItem];
          }
        } else {
          updatedPortfolio = [newItem];
        }
        await setDoc(userDocRef, { portfolio: updatedPortfolio }, { merge: true });
        setPortfolio(updatedPortfolio);
      } catch (error) {
        console.error("Error adding stock to portfolio:", error);
      }
    } else {
      console.log("User must be logged in to add to portfolio");
    }
  };

  useEffect(() => {
    const originalError = console.error;
    console.error = (...args) => {
      if (args[0] && typeof args[0] === 'string' && args[0].includes('ResizeObserver loop')) {
        return;
      }
      originalError.call(console, ...args);
    };

    const handleError = (event: ErrorEvent) => {
      if (event.message.includes('ResizeObserver loop')) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    };

    window.addEventListener('error', handleError);

    return () => {
      console.error = originalError;
      window.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sa-funkar-det" element={<HowItWorks />} />
        <Route 
          path="/stocks" 
          element={<StockListPage addToWatchlist={addToWatchlist} addToPortfolio={addToPortfolio} watchlistIds={watchlistIds} />} 
        />
        <Route 
          path="/watchlist" 
          element={<WatchlistPage watchlistIds={watchlistIds} removeFromWatchlist={removeFromWatchlist} addToPortfolio={addToPortfolio} />} 
        />
        <Route 
          path="/stock/:slug" 
          element={<StockDetailPage addToWatchlist={addToWatchlist} addToPortfolio={addToPortfolio} isInWatchlist={watchlistIds.includes} />} 
        />
        <Route path="/skapa-konto" element={<SignUp />} />
        <Route path="/mina-sidor" element={<MinaSidor portfolio={portfolio} />} />
        <Route path="/logga-in" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaChartLine, FaInfoCircle } from 'react-icons/fa';

const Header: React.FC = () => {
  return (
    <header className="bg-blue-600 text-white">
      <div className="container mx-auto px-4 py-4">
        <nav className="flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold">StockLab</Link>
          <ul className="flex space-x-6">
            <li>
              <Link to="/stocks" className="flex items-center hover:text-blue-200">
                <FaChartLine className="mr-2" />
                <span>Aktier</span>
              </Link>
            </li>
            <li>
              <Link to="/sa-funkar-det" className="flex items-center hover:text-blue-200">
                <FaInfoCircle className="mr-2" />
                <span>Så funkar det</span>
              </Link>
            </li>
            <li>
              <Link to="/mina-sidor" className="flex items-center hover:text-blue-200">
                <FaUser className="mr-2" />
                <span>Mina Sidor</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

// Suppress ResizeObserver error
const suppressResizeObserverError = () => {
  const resizeObserverError = console.error;
  console.error = (...args: any[]) => {
    if (args.length > 1 && typeof args[1] === 'string' && args[1].includes('ResizeObserver loop')) {
      return;
    }
    resizeObserverError.apply(console, args);
  };
};

suppressResizeObserverError();

window.addEventListener('error', (event) => {
  if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
    event.stopImmediatePropagation();
    event.preventDefault();
    return false;
  }
}, true);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

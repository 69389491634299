import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8zFUUgEkrN4BqxDhswbAZuJj1fijrdWM",
  authDomain: "stock-lab-8b62b.firebaseapp.com",
  projectId: "stock-lab-8b62b",
  storageBucket: "stock-lab-8b62b.appspot.com",
  messagingSenderId: "1011121225288",
  appId: "1:1011121225288:web:f5c512b53768297e78a27d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Storage
export const storage = getStorage(app);
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebase/config';
import { signOut, updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Header from '../components/Header';
import { fetchStockDataById, StockData } from '../utils/api';
import Watchlist from '../components/Watchlist';

interface PortfolioItem extends StockData {
  quantity: number;
  buyPrice: number;
}

interface MinaSidorProps {
  portfolio: PortfolioItem[];
}

const MinaSidor: React.FC<MinaSidorProps> = ({ portfolio: initialPortfolio }) => {
  const [userData, setUserData] = useState<any>(null);
  const [portfolio, setPortfolio] = useState<PortfolioItem[]>([]);
  const [recommendations, setRecommendations] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('portfolio');
  const [newName, setNewName] = useState('');
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [watchlist, setWatchlist] = useState<StockData[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();

          if (userData) {
            setUserData({
              name: userData.name || user.displayName,
              email: user.email,
              profilePicture: userData.profilePicture || user.photoURL
            });
            setProfilePicture(userData.profilePicture || user.photoURL);
          } else {
            setUserData({
              name: user.displayName,
              email: user.email,
              profilePicture: user.photoURL
            });
            setProfilePicture(user.photoURL);
          }

          const fetchedRecommendations = await fetchRecommendations(user.uid);
          setRecommendations(fetchedRecommendations);

          // Fetch full stock data for portfolio items
          const updatedPortfolio = await Promise.all(
            initialPortfolio.map(async (item) => {
              try {
                const stockData = await fetchStockDataById(item.id);
                return {
                  ...stockData,
                  quantity: item.quantity,
                  buyPrice: item.buyPrice
                };
              } catch (error) {
                console.error(`Error fetching data for stock ${item.id}:`, error);
                return {
                  ...item,
                  name: 'Data unavailable',
                  ticker: 'N/A',
                  country: 'N/A',
                  sector: 'N/A',
                  totalRank: 0,
                  qualityRank: 0,
                  valueRank: 0,
                  momentumRank: 0,
                  currentPrice: 0,
                  bearPrice: 0,
                  basePrice: 0,
                  bullPrice: 0
                };
              }
            })
          );
          setPortfolio(updatedPortfolio);

          // Load watchlist
          if (userData && userData.watchlist) {
            const watchlistData = await Promise.all(
              userData.watchlist.map((id: string) => fetchStockDataById(id))
            );
            setWatchlist(watchlistData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to load user data. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    loadData();
  }, [initialPortfolio]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to home page after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleNameChange = async (e: React.FormEvent) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      try {
        await updateProfile(user, { displayName: newName });
        
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, { name: newName });

        setUserData({ ...userData, name: newName });
        setNewName('');
        setIsNameModalOpen(false);
        alert('Name updated successfully!');
      } catch (error) {
        console.error("Error updating name:", error);
        alert('Failed to update name. Please try again.');
      }
    }
  };

  const handleProfilePictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const user = auth.currentUser;
        if (user) {
          const storageRef = ref(storage, `profile_pictures/${user.uid}`);
          await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(storageRef);
          
          await updateProfile(user, { photoURL: downloadURL });
          
          const userDocRef = doc(db, 'users', user.uid);
          await updateDoc(userDocRef, { profilePicture: downloadURL });

          setProfilePicture(downloadURL);
          setUserData({ ...userData, profilePicture: downloadURL });
          alert('Profile picture updated successfully!');
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
        alert('Failed to update profile picture. Please try again.');
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const removeFromWatchlist = async (stockId: string) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        if (userData && userData.watchlist) {
          const updatedWatchlist = userData.watchlist.filter((id: string) => id !== stockId);
          await updateDoc(userDocRef, { watchlist: updatedWatchlist });
          setWatchlist(watchlist.filter(stock => stock.id !== stockId));
        }
      } catch (error) {
        console.error("Error removing stock from watchlist:", error);
        alert('Failed to remove stock from watchlist. Please try again.');
      }
    }
  };

  const addToPortfolio = async (id: string, quantity: number, buyPrice: number) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        
        const stockData = await fetchStockDataById(id);
        const newPortfolioItem: PortfolioItem = {
          ...stockData,
          quantity,
          buyPrice,
        };

        let updatedPortfolio: PortfolioItem[];
        if (userData && userData.portfolio) {
          const existingItemIndex = userData.portfolio.findIndex((item: PortfolioItem) => item.id === id);
          if (existingItemIndex !== -1) {
            // Update existing item
            updatedPortfolio = [...userData.portfolio];
            updatedPortfolio[existingItemIndex].quantity += quantity;
            updatedPortfolio[existingItemIndex].buyPrice = (updatedPortfolio[existingItemIndex].buyPrice * updatedPortfolio[existingItemIndex].quantity + buyPrice * quantity) / (updatedPortfolio[existingItemIndex].quantity + quantity);
          } else {
            // Add new item
            updatedPortfolio = [...userData.portfolio, newPortfolioItem];
          }
        } else {
          updatedPortfolio = [newPortfolioItem];
        }

        await updateDoc(userDocRef, { portfolio: updatedPortfolio });
        
        // Update local state
        setPortfolio(updatedPortfolio);
        
        alert('Stock added to portfolio successfully!');
      } catch (error) {
        console.error("Error adding stock to portfolio:", error);
        alert('Failed to add stock to portfolio. Please try again.');
      }
    }
  };

  const sellStock = async (stockId: string) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        if (userData && userData.portfolio) {
          const updatedPortfolio = userData.portfolio.filter((item: PortfolioItem) => item.id !== stockId);
          await updateDoc(userDocRef, { portfolio: updatedPortfolio });
          setPortfolio(portfolio.filter(stock => stock.id !== stockId));
          alert('Stock sold and removed from portfolio successfully!');
        }
      } catch (error) {
        console.error("Error selling stock:", error);
        alert('Failed to sell stock. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!userData) {
    return <div>Please log in to view this page.</div>;
  }

  const renderNameChangeModal = () => {
    if (!isNameModalOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Update Name</h3>
            <div className="mt-2 px-7 py-3">
              <form onSubmit={handleNameChange}>
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="New name"
                />
                <div className="items-center px-4 py-3">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
            <div className="items-center px-4 py-3">
              <button
                onClick={() => setIsNameModalOpen(false)}
                className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'portfolio':
        return (
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Min Portfölj</h2>
            {portfolio.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticker</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Namn</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Antal</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Köpkurs</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nuvarande Kurs</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Totalt Värde</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Q</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">V</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">M</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tot</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Åtgärder</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {portfolio.map((stock, index) => {
                      const totalValue = stock.quantity * stock.currentPrice;
                      return (
                        <tr key={stock.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.ticker || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.name || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.quantity}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.buyPrice?.toFixed(2) || 'N/A'} kr</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.currentPrice?.toFixed(2) || 'N/A'} kr</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{totalValue?.toFixed(2) || 'N/A'} kr</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.qualityRank?.toFixed(1) || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.valueRank?.toFixed(1) || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.momentumRank?.toFixed(1) || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.totalRank?.toFixed(1) || 'N/A'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              onClick={() => sellStock(stock.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              Sälj
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-gray-500 italic">Du har inga aktier i din portfölj än.</p>
            )}
          </div>
        );
      case 'watchlist':
        return (
          <div className="bg-white shadow rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Bevakningslista</h2>
            <Watchlist 
              watchlist={watchlist} 
              removeFromWatchlist={removeFromWatchlist} 
              addToPortfolio={addToPortfolio}
            />
          </div>
        );
      case 'settings':
        return (
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Inställningar</h2>
            <p>E-post: {userData.email}</p>
            <button
              onClick={() => setIsNameModalOpen(true)}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Uppdatera namn
            </button>
            <button className="mt-4 ml-4 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">Ändra lösenord</button>
            <div className="mt-4">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" />
                <span className="ml-2">Notifikationer</span>
              </label>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <main className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <div className="relative w-20 h-20 mr-4">
              <img 
                src={userData?.profilePicture || '/placeholder-profile.jpg'} 
                alt="Profile" 
                className="w-full h-full rounded-full object-cover"
              />
              <button 
                onClick={triggerFileInput}
                className="absolute bottom-0 right-0 bg-blue-500 text-white rounded-full p-1 text-xs"
              >
                Edit
              </button>
              <input 
                type="file" 
                ref={fileInputRef}
                onChange={handleProfilePictureChange}
                className="hidden"
                accept="image/*"
              />
            </div>
            <h1 className="text-2xl font-bold">{userData.name}</h1>
          </div>
          <button onClick={handleLogout} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
            Logga ut
          </button>
        </div>

        {/* Tabs */}
        <div className="mb-8">
          <nav className="flex space-x-4">
            <button
              onClick={() => setActiveTab('portfolio')}
              className={`px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === 'portfolio' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Portfölj
            </button>
            <button
              onClick={() => setActiveTab('watchlist')}
              className={`px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === 'watchlist' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Bevakningslista
            </button>
            <button
              onClick={() => setActiveTab('settings')}
              className={`px-3 py-2 font-medium text-sm rounded-md ${
                activeTab === 'settings' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Inställningar
            </button>
          </nav>
        </div>

        {/* Tab Content */}
        {renderTabContent()}
        {renderNameChangeModal()}
      </main>
    </div>
  );
};

// Placeholder functions - replace these with actual implementations
const fetchUserData = async (uid: string) => {
  // Implement actual user data fetching
  return { name: 'John Doe', email: 'john@example.com' };
};

const fetchRecommendations = async (uid: string) => {
  // Implement actual recommendations fetching
  return { buy: [], sell: [] };
};

export default MinaSidor;
import axios from 'axios';

export interface StockData {
  id: string;  // Internal ID (Column A)
  ticker: string;  // Ticker symbol (Column B)
  name: string;
  country: string;
  sector: string;
  totalRank: number;
  qualityRank: number;
  valueRank: number;
  momentumRank: number;
  currentPrice: number;
  bearPrice: number;
  basePrice: number;
  bullPrice: number;
  trendStatus: string; // Add this line
}

const SPREADSHEET_ID = '1JgXAgqtND4-x-BmwnjuHjnwHzph9kBMRAEw55fEIqbI'; // Verify this ID
const SHEET_NAME = 'Copy'; // Verify this sheet name
const API_KEY = 'AIzaSyAcaWoL1i__SSXMiWU0Xe1l7xy1iR28h80'; // Verify this API key

export const fetchStockData = async (): Promise<StockData[]> => {
  try {
    const response = await axios.get(
      `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}!A1:S1000?key=${API_KEY}`
    );

    console.log('API Response:', response.data); // Log the response for debugging

    if (!response.data || !response.data.values || response.data.values.length === 0) {
      throw new Error('No data received from the API');
    }

    const [headers, ...rows] = response.data.values;

    return rows.map((row: any[]) => ({
      id: row[0], // Column A (Internal ID)
      ticker: row[1], // Column B (Ticker)
      name: row[2], // Column C (Name)
      country: row[3], // Column D
      sector: row[6], // Column G
      qualityRank: parseFloat(row[8]) || 0, // Column I
      valueRank: parseFloat(row[9]) || 0, // Column J
      momentumRank: parseFloat(row[10]) || 0, // Column K
      totalRank: parseFloat(row[11]) || 0, // Column L
      currentPrice: parseFloat(row[12]) || 0, // Column M
      bearPrice: parseFloat(row[15]) || 0, // Column P
      basePrice: parseFloat(row[16]) || 0, // Column Q
      bullPrice: parseFloat(row[17]) || 0, // Column R
      trendStatus: row[34] || 'N/A', // Column AI
    }));
  } catch (error) {
    console.error('Error fetching stock data:', error);
    if (axios.isAxiosError(error)) {
      console.error('Axios error details:', error.response?.data);
    }
    throw error;
  }
};

export const fetchStockDataById = async (id: string): Promise<StockData> => {
  try {
    const response = await axios.get(
      `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}!A1:S1000?key=${API_KEY}`
    );

    if (!response.data || !response.data.values || response.data.values.length === 0) {
      throw new Error('No data received from the API');
    }

    const [headers, ...rows] = response.data.values;
    const stockRow = rows.find((row: any[]) => row[0] === id);

    if (!stockRow) {
      throw new Error(`Stock with ID ${id} not found`);
    }

    return {
      id: stockRow[0],
      ticker: stockRow[1],
      name: stockRow[2],
      country: stockRow[3],
      sector: stockRow[6],
      qualityRank: parseFloat(stockRow[8]) || 0,
      valueRank: parseFloat(stockRow[9]) || 0,
      momentumRank: parseFloat(stockRow[10]) || 0,
      totalRank: parseFloat(stockRow[11]) || 0,
      currentPrice: parseFloat(stockRow[12]) || 0,
      bearPrice: parseFloat(stockRow[15]) || 0,
      basePrice: parseFloat(stockRow[16]) || 0,
      bullPrice: parseFloat(stockRow[17]) || 0,
      trendStatus: stockRow[34] || 'N/A', // Column AI
    };
  } catch (error) {
    console.error('Error fetching stock data by ID:', error);
    throw error;
  }
};
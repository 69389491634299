import React, { useState, useEffect } from 'react';
import { StockData, fetchStockData } from '../utils/api';
import StockList from '../components/StockList';
import StockTable from '../components/StockTable';
import FilterOptions from '../components/FilterOptions';
import Header from '../components/Header';
import { FaThLarge, FaTable } from 'react-icons/fa';

interface StockListPageProps {
  addToWatchlist: (stockId: string) => Promise<void>;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  watchlistIds: string[];
}

const StockListPage: React.FC<StockListPageProps> = ({ addToWatchlist, addToPortfolio, watchlistIds }) => {
  const [stocks, setStocks] = useState<StockData[]>([]);
  const [filteredStocks, setFilteredStocks] = useState<StockData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [viewMode, setViewMode] = useState<'card' | 'table'>('card');

  useEffect(() => {
    const loadStocks = async () => {
      try {
        const fetchedStocks = await fetchStockData();
        setStocks(fetchedStocks);
        setFilteredStocks(fetchedStocks);
      } catch (err) {
        setError('Failed to load stock data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadStocks();
  }, []);

  const handleAddToPortfolio = async (stockId: string, quantity: number, buyPrice: number) => {
    await addToPortfolio(stockId, quantity, buyPrice);
    setShowSuccessBanner(true);
    setTimeout(() => setShowSuccessBanner(false), 3000);
  };

  if (isLoading) {
    return <div className="text-center mt-8">Loading stocks...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div>
      <Header />
      {showSuccessBanner && (
        <div className="fixed top-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50">
          Stock added to portfolio successfully!
        </div>
      )}
      <main className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Stock List</h1>
          <div className="flex space-x-2">
            <button
              onClick={() => setViewMode('card')}
              className={`p-2 rounded ${viewMode === 'card' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
            >
              <FaThLarge />
            </button>
            <button
              onClick={() => setViewMode('table')}
              className={`p-2 rounded ${viewMode === 'table' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
            >
              <FaTable />
            </button>
          </div>
        </div>
        <FilterOptions stocks={stocks} setFilteredStocks={setFilteredStocks} />
        {viewMode === 'card' ? (
          <StockList 
            stocks={filteredStocks} 
            addToWatchlist={addToWatchlist}
            addToPortfolio={handleAddToPortfolio}
            watchlistIds={watchlistIds} 
          />
        ) : (
          <StockTable
            stocks={filteredStocks}
            addToWatchlist={addToWatchlist}
            addToPortfolio={handleAddToPortfolio}
            watchlistIds={watchlistIds}
          />
        )}
      </main>
    </div>
  );
};

export default StockListPage;
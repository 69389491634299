import React, { useState } from 'react';
import { StockData } from '../utils/api';
import AddToPortfolioModal from './AddToPortfolioModal';
import { getRankColor } from '../utils/helpers';
import { FiPlusCircle, FiCheckCircle, FiBriefcase } from 'react-icons/fi';

interface StockTableProps {
  stocks: StockData[];
  addToWatchlist: (stockId: string) => Promise<void>;
  addToPortfolio: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  watchlistIds: string[];
}

const StockTable: React.FC<StockTableProps> = ({ stocks, addToWatchlist, addToPortfolio, watchlistIds }) => {
  const [selectedStock, setSelectedStock] = useState<StockData | null>(null);

  const handleAddToPortfolio = async (stockId: string, quantity: number, buyPrice: number) => {
    await addToPortfolio(stockId, quantity, buyPrice);
    setSelectedStock(null);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticker</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Price</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Q</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">V</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">M</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tot</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {stocks.map((stock, index) => (
            <tr key={stock.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{stock.ticker}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stock.currentPrice.toFixed(2)}</td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${getRankColor(stock.qualityRank)}`}>{stock.qualityRank.toFixed(1)}</td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${getRankColor(stock.valueRank)}`}>{stock.valueRank.toFixed(1)}</td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${getRankColor(stock.momentumRank)}`}>{stock.momentumRank.toFixed(1)}</td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${getRankColor(stock.totalRank)}`}>{stock.totalRank.toFixed(1)}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  onClick={() => addToWatchlist(stock.id)}
                  className={`p-2 rounded-full transition-colors duration-300 ${
                    watchlistIds.includes(stock.id) ? 'text-gray-400 cursor-not-allowed' : 'text-gray-600 hover:text-gray-800'
                  }`}
                  disabled={watchlistIds.includes(stock.id)}
                  title={watchlistIds.includes(stock.id) ? 'In Watchlist' : 'Add to Watchlist'}
                >
                  {watchlistIds.includes(stock.id) ? <FiCheckCircle size={20} /> : <FiPlusCircle size={20} />}
                </button>
                <button
                  onClick={() => setSelectedStock(stock)}
                  className="p-2 rounded-full text-gray-600 hover:text-gray-800 transition-colors duration-300 ml-2"
                  title="Add to Portfolio"
                >
                  <FiBriefcase size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedStock && (
        <AddToPortfolioModal
          stock={selectedStock}
          onAdd={handleAddToPortfolio}
          onClose={() => setSelectedStock(null)}
        />
      )}
    </div>
  );
};

export default StockTable;
import React, { useState } from 'react';
import { StockData } from '../utils/api';

interface AddToPortfolioModalProps {
  stock: StockData;
  onAdd: (stockId: string, quantity: number, buyPrice: number) => Promise<void>;
  onClose: () => void;
}

const AddToPortfolioModal: React.FC<AddToPortfolioModalProps> = ({ stock, onAdd, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const [buyPrice, setBuyPrice] = useState(stock.currentPrice);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(stock.id, quantity, buyPrice);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm">
      <div className="relative bg-white w-96 shadow-lg rounded-md p-5">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Add {stock.name} to Portfolio</h3>
          <form onSubmit={handleSubmit} className="mt-2 px-7 py-3">
            <div className="mb-4">
              <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                min="1"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="buyPrice" className="block text-sm font-medium text-gray-700">Buy Price</label>
              <input
                type="number"
                id="buyPrice"
                value={buyPrice}
                onChange={(e) => setBuyPrice(Number(e.target.value))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                step="0.01"
                min="0"
                required
              />
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                Add to Portfolio
              </button>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddToPortfolioModal;
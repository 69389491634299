import React, { useState, useEffect } from 'react';
import { StockData } from '../utils/api';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select, { MultiValue } from 'react-select';
import { FaSearch, FaChartLine, FaBalanceScale, FaBolt, FaUndo } from 'react-icons/fa';

interface FilterOptionsProps {
  stocks: StockData[];
  setFilteredStocks: React.Dispatch<React.SetStateAction<StockData[]>>;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ stocks, setFilteredStocks }) => {
  const [name, setName] = useState('');
  const [sectors, setSectors] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [minTotalRank, setMinTotalRank] = useState('');
  const [maxTotalRank, setMaxTotalRank] = useState('');
  const [minQualityRank, setMinQualityRank] = useState('');
  const [maxQualityRank, setMaxQualityRank] = useState('');
  const [minValueRank, setMinValueRank] = useState('');
  const [maxValueRank, setMaxValueRank] = useState('');
  const [minMomentumRank, setMinMomentumRank] = useState('');
  const [maxMomentumRank, setMaxMomentumRank] = useState('');
  const [minBearPrice, setMinBearPrice] = useState('');
  const [maxBearPrice, setMaxBearPrice] = useState('');

  const [uniqueSectors, setUniqueSectors] = useState<string[]>([]);
  const [uniqueCountries, setUniqueCountries] = useState<string[]>([]);

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  useEffect(() => {
    const sectorSet = new Set(stocks.map(stock => stock.sector));
    const countrySet = new Set(stocks.map(stock => stock.country));
    setUniqueSectors(Array.from(sectorSet).sort());
    setUniqueCountries(Array.from(countrySet).sort());
  }, [stocks]);

  const calculatePricePercentage = (stock: StockData) => {
    return ((stock.currentPrice - stock.bearPrice) / (stock.bullPrice - stock.bearPrice)) * 100;
  };

  useEffect(() => {
    handleFilter();
  }, [name, sectors, countries, minTotalRank, maxTotalRank, minQualityRank, maxQualityRank, minValueRank, maxValueRank, minMomentumRank, maxMomentumRank, minBearPrice, maxBearPrice, stocks]);

  const handleFilter = () => {
    const filtered = stocks.filter(stock => 
      (name === '' || stock.name.toLowerCase().includes(name.toLowerCase())) &&
      (sectors.length === 0 || sectors.includes(stock.sector)) &&
      (countries.length === 0 || countries.includes(stock.country)) &&
      (minTotalRank === '' || stock.totalRank >= parseInt(minTotalRank)) &&
      (maxTotalRank === '' || stock.totalRank <= parseInt(maxTotalRank)) &&
      (minQualityRank === '' || stock.qualityRank >= parseInt(minQualityRank)) &&
      (maxQualityRank === '' || stock.qualityRank <= parseInt(maxQualityRank)) &&
      (minValueRank === '' || stock.valueRank >= parseInt(minValueRank)) &&
      (maxValueRank === '' || stock.valueRank <= parseInt(maxValueRank)) &&
      (minMomentumRank === '' || stock.momentumRank >= parseInt(minMomentumRank)) &&
      (maxMomentumRank === '' || stock.momentumRank <= parseInt(maxMomentumRank)) &&
      (minBearPrice === '' || calculatePricePercentage(stock) >= parseFloat(minBearPrice)) &&
      (maxBearPrice === '' || calculatePricePercentage(stock) <= parseFloat(maxBearPrice))
    );
    setFilteredStocks(filtered);
  };

  const handleSectorChange = (sector: string) => {
    setSectors(prev => 
      prev.includes(sector) ? prev.filter(s => s !== sector) : [...prev, sector]
    );
  };

  const handleCountryChange = (country: string) => {
    setCountries(prev => 
      prev.includes(country) ? prev.filter(c => c !== country) : [...prev, country]
    );
  };

  const applyPredefinedFilter = (filterType: string) => {
    switch (filterType) {
      case 'Value Hunter':
        setMinValueRank('4');
        setMaxValueRank('');
        setMinQualityRank('');
        setMaxQualityRank('');
        setMinMomentumRank('');
        setMaxMomentumRank('');
        break;
      case 'Momentum Trader':
        setMinMomentumRank('4');
        setMaxMomentumRank('');
        setMinQualityRank('');
        setMaxQualityRank('');
        setMinValueRank('');
        setMaxValueRank('');
        break;
      case 'Contrarian':
        setMinMomentumRank('0');
        setMaxMomentumRank('2');
        setMinQualityRank('4');
        setMaxQualityRank('');
        setMinValueRank('4');
        setMaxValueRank('');
        break;
      default:
        // Reset all filters
        setMinValueRank('');
        setMaxValueRank('');
        setMinQualityRank('');
        setMaxQualityRank('');
        setMinMomentumRank('');
        setMaxMomentumRank('');
    }
    // Reset other filters
    setName('');
    setSectors([]);
    setCountries([]);
    setMinTotalRank('');
    setMaxTotalRank('');
    setMinBearPrice('');
    setMaxBearPrice('');
  };

  const handleSliderChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (value: number | number[]) => {
    if (Array.isArray(value)) {
      setter(value[0].toString());
    } else {
      setter(value.toString());
    }
  };

  const resetAllFilters = () => {
    setName('');
    setSectors([]);
    setCountries([]);
    setMinTotalRank('');
    setMaxTotalRank('');
    setMinQualityRank('');
    setMaxQualityRank('');
    setMinValueRank('');
    setMaxValueRank('');
    setMinMomentumRank('');
    setMaxMomentumRank('');
    setMinBearPrice('');
    setMaxBearPrice('');
  };

  const sliderStyle = {
    track: {
      backgroundColor: '#E5E7EB',
    },
    rail: {
      backgroundColor: '#F3F4F6',
    },
    handle: {
      borderColor: '#3B82F6',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 2px #3B82F6',
    },
    active: {
      backgroundColor: '#3B82F6',
    },
  };

  return (
    <div className="mb-8 bg-white rounded-lg shadow-sm p-6">
      <div className="flex flex-wrap -mx-2">
        {/* Predefined Searches */}
        <div className="w-full px-2 mb-6">
          <div className="flex space-x-4">
            {[
              { name: 'Value Hunter', icon: <FaBalanceScale /> },
              { name: 'Momentum Trader', icon: <FaChartLine /> },
              { name: 'Contrarian', icon: <FaBolt /> },
              { name: 'Reset', icon: <FaUndo /> }
            ].map(({ name, icon }) => (
              <button
                key={name}
                onClick={() => name === 'Reset' ? resetAllFilters() : applyPredefinedFilter(name)}
                className="flex items-center px-4 py-2 text-sm bg-gray-50 text-gray-700 rounded-full hover:bg-gray-100 transition-colors duration-200"
              >
                <span className="mr-2">{icon}</span>
                {name}
              </button>
            ))}
          </div>
        </div>

        {/* Basic Filters */}
        <div className="w-full flex space-x-4 mb-6">
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                placeholder="Search stocks..."
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 text-sm border border-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          <button
            onClick={() => setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen)}
            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
          >
            {isAdvancedFiltersOpen ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>

        {/* Advanced Filters */}
        {isAdvancedFiltersOpen && (
          <div className="w-full space-y-6">
            {/* Sector and Country Filters */}
            <div className="flex space-x-4">
              <Select
                isMulti
                placeholder="Select Sectors"
                options={uniqueSectors.map(sector => ({ value: sector, label: sector }))}
                onChange={(selectedOptions: MultiValue<{ value: string; label: string }>) => 
                  setSectors(selectedOptions.map(option => option.value))
                }
                className="w-1/2"
                classNamePrefix="react-select"
              />
              <Select
                isMulti
                placeholder="Select Countries"
                options={uniqueCountries.map(country => ({ value: country, label: country }))}
                onChange={(selectedOptions: MultiValue<{ value: string; label: string }>) => 
                  setCountries(selectedOptions.map(option => option.value))
                }
                className="w-1/2"
                classNamePrefix="react-select"
              />
            </div>

            {/* Rank Sliders */}
            <div className="space-y-6">
              {['Total', 'Quality', 'Value', 'Momentum'].map((rankType) => (
                <div key={rankType} className="relative">
                  <label className="block text-sm font-medium text-gray-700 mb-2">{rankType} Rank</label>
                  <div className="h-10">
                    <Slider
                      range
                      min={-7}
                      max={7}
                      value={[
                        eval(`min${rankType}Rank`) === '' ? -7 : parseInt(eval(`min${rankType}Rank`)),
                        eval(`max${rankType}Rank`) === '' ? 7 : parseInt(eval(`max${rankType}Rank`))
                      ]}
                      onChange={(value) => {
                        if (Array.isArray(value)) {
                          eval(`setMin${rankType}Rank(value[0].toString())`);
                          eval(`setMax${rankType}Rank(value[1].toString())`);
                        }
                      }}
                      trackStyle={[sliderStyle.active]}
                      railStyle={sliderStyle.rail}
                      handleStyle={[sliderStyle.handle, sliderStyle.handle]}
                    />
                  </div>
                  <div className="flex justify-between mt-1 text-xs text-gray-500">
                    <span>{eval(`min${rankType}Rank`) === '' ? -7 : parseInt(eval(`min${rankType}Rank`))}</span>
                    <span>{eval(`max${rankType}Rank`) === '' ? 7 : parseInt(eval(`max${rankType}Rank`))}</span>
                  </div>
                </div>
              ))}
            </div>

            {/* Price Range Slider */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Price Range (%)</label>
              <Slider
                range
                min={0}
                max={100}
                value={[minBearPrice === '' ? 0 : parseFloat(minBearPrice), maxBearPrice === '' ? 100 : parseFloat(maxBearPrice)]}
                onChange={(value) => {
                  if (Array.isArray(value)) {
                    setMinBearPrice(value[0].toString());
                    setMaxBearPrice(value[1].toString());
                  }
                }}
                trackStyle={[sliderStyle.active]}
                railStyle={sliderStyle.rail}
                handleStyle={[sliderStyle.handle, sliderStyle.handle]}
              />
              <div className="flex justify-between mt-1 text-xs text-gray-500">
                <span>{minBearPrice === '' ? 0 : parseFloat(minBearPrice)}%</span>
                <span>{maxBearPrice === '' ? 100 : parseFloat(maxBearPrice)}%</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterOptions;
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

const HowItWorks: React.FC = () => {
  return (
    <div className="bg-gray-100">
      <Header />
      <main>
        {/* Hero section */}
        <div className="bg-indigo-700 text-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 text-center">
            <p className="text-base font-semibold tracking-wide uppercase">Analyserar tusentals aktier med AI för att hjälpa dig göra bättre investeringar</p>
            <h1 className="mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl">AI-baserad aktieanalys – Se hur din portfölj kan växa</h1>
            <p className="max-w-xl mt-5 mx-auto text-xl">Få aktieanalys baserad på värde, momentum och kvalitet. Med hjälp av vår Total Score kan du identifiera de bästa investeringsmöjligheterna.</p>
            <Link to="/signup" className="mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">
              Prova gratis och optimera din portfölj
            </Link>
          </div>
        </div>

        {/* Introduction to analysis model */}
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Så fungerar vår analys</h2>
            <p className="mt-4 text-lg text-gray-500">Vi analyserar tusentals aktier varje dag och bedömer dem utifrån tre centrala värden: Value, Momentum, och Quality. Dessa nyckelfaktorer ger dig insikter i aktiens prisvärdhet, utvecklingshastighet och övergripande kvalitet. Dessa värden sammanställs till ett Total Score, där ett högre värde innebär bättre investeringspotential.</p>
            <Link to="/analysis-method" className="mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              Läs mer om vår analysmetod
            </Link>
          </div>
        </div>

        {/* Results and benefits of Total Score */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Bevisat bättre avkastning med Total Score</h2>
              <p className="mt-4 text-lg text-gray-500">Vi har observerat att aktier med ett högre Total Score tenderar att prestera bättre på börsen. Detta hjälper dig att fatta mer välgrundade beslut och öka din portföljs avkastning.</p>
              <Link to="/signup" className="mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                Prova vår analys gratis idag
              </Link>
            </div>
          </div>
        </div>

        {/* Personal recommendations */}
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Personliga köp- och säljförslag anpassade för dig</h2>
            <p className="mt-4 text-lg text-gray-500">Vår AI övervakar din portfölj i realtid och ger dig personliga rekommendationer när det är dags att köpa eller sälja aktier. På så sätt kan du optimera dina investeringar och ligga steget före marknaden.</p>
            <Link to="/ai-suggestions" className="mt-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              Aktivera AI-förslag för din portfölj
            </Link>
          </div>
        </div>

        {/* FAQ section */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center sm:text-4xl">Vanliga frågor om vår tjänst</h2>
            <div className="mt-12">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
                {[
                  { question: "Hur ofta uppdateras Total Score?", answer: "Total Score uppdateras dagligen efter börsens stängning." },
                  { question: "Hur fungerar era köp- och säljrekommendationer?", answer: "Våra rekommendationer baseras på AI-analys av marknadsdata, företagsrapporter och globala ekonomiska trender." },
                  { question: "Vad är skillnaden mellan era analyser och andra analyssystem?", answer: "Vår AI-drivna approach ger mer precisa och personanpassade rekommendationer jämfört med traditionella system." },
                ].map((faq) => (
                  <div key={faq.question}>
                    <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                    <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
            <div className="mt-8 text-center">
              <Link to="/contact" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                Har du fler frågor? Kontakta oss! <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>

        {/* Final CTA */}
        <div className="bg-indigo-700">
          <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Redo att ta dina investeringar till nästa nivå?</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-indigo-200">
              Börja använda vår AI-baserade analys och få bättre kontroll över din portfölj. Prova gratis idag!
            </p>
            <Link to="/signup" className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto">
              Börja din kostnadsfria testperiod
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HowItWorks;